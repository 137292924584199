<template>

    <order-payment-section/>
    <h3 class="checkout__title">Payment Details</h3>
   

    <div class="form-group--block">
      <input
        class="form-check-input"
        type="checkbox"
        id="checkboxAgree"
        value="1"
        v-model="formData.agree"
      />
      <label class="label-checkbox" for="checkboxAgree"
        ><b class="text-heading"
          >I have read and agree to pay the website
          <u class="text-success"> <a href="/terms" target="_blank"> terms and conditions </a></u><span>*</span></b
        ></label
      >
    </div>

    <button
      type="button"
      class="btn checkout__order"
      :class="(cart.shipping_method ) ? '' : 'disabled'"
      @click.prevent="handlePlaceOrder"
      :disabled="!formData.agree || donate == '' "
    >
      {{ isLoading ? "Processing order..." : "Place an Order" }}
    </button>

     <div class="cc-selector-2">
       <img src="/img/credit/all.jpg" alt="">
      </div>   

      <p>
          Your personal data will be used to process your order, support your
          experience throughout this website, and for other purposes described in
          our
          <span class="text-success"><a href="/privacy" target="_blank"> privacy policy.</a></span>
      </p>

      <div class="text-center" style="margin:15px;color:#ddd">Or</div>

      <div class="text-center">
        <a href="" class="back-to-shopping" @click.prevent="$router.back()">Back to Delivery detail</a>
      </div>

      
      
</template>

<script>
import { mapActions,mapGetters } from "vuex";
import OrderPaymentSection from "./OrderPaymentSection";
export default {
  components:{
    OrderPaymentSection
  },
  data() {
    return {
      formData: {
        agree: false,
        payment: {
          method: "cashondelivery",
        },
      },
    };
  },

  props: {
    onChangePage: {
      type: Function,
    },
  },

  computed: {
    ...mapGetters("cart", ["cart", "isEmpty", "items", "unitPriceTotal", "formated_base_taxes",'isLoading','donate']),
  },

  methods: {
    ...mapActions("cart", [
      "savePayment",
      "saveOrder",
      "saveCoupon",
      "fetchCart",
      "removeCoupon",
      "saveDonate",
    ]),
    handlePlaceOrder() {
      this.saveOrder().then((responce) => {
        if (responce.redirect_url) {
          window.location.href = responce.redirect_url;
          // this.$router.push({ name: "order-tracking" });
        }
      });
    },
  },
};
</script>

<style scoped>
.picker > input {
  font-size: 14px;
  width: 307px;
  height: 40px;
}
.rush-text {
  margin-bottom: 1px;
  color: #5a6171;
}
.icon-truck {
  font-size: 23px;
  color: #5a6171;
}
.back-to-shopping{
  font-weight: bold;
}
.cc-selector-2{
  margin: 15px 0;
}
</style>

<style>
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: #ff7200;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #fff;
    border-color: #ff7200;
}
</style>
