<template>
    <h3 class="checkout__title">Your Order</h3>

    <div class="checkout__products" v-if="!isEmpty">
      <div class="row">
        <div class="col-8">
          <div class="checkout__label">PRODUCT</div>
        </div>
        <div class="col-4 text-right">
          <div class="checkout__label">TOTAL</div>
        </div>
      </div>
      <div class="checkout__list">
        <div
          class="checkout__product__item"
          v-for="(item, index) in items"
          :key="index"
        >
          <div class="checkout-product">
            <div class="product__name">
              {{ item.name }}<br>
              <small>({{ item.formated_price }}x{{ item.quantity }})</small>
            </div>
          </div>
          <div class="checkout-price">{{ item.formated_total }}</div>
        </div>
      </div>
      
      <div class="row" v-if="true">
        <div class="col-8">
          <div class="checkout__label">Unit Price Total</div>
        </div>
        <div class="col-4 text-right">
          <div class="checkout__label">
            {{ $formatNumber(unitPriceTotal,  cart.cart_currency_code) }}
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-8">
          <div class="checkout__label">Product Discount</div>
        </div>
        <div class="col-4 text-right">
          <div class="checkout__label">
            -{{ $formatNumber(productDiscount, cart.cart_currency_code) }}
          </div>
        </div>
      </div>

      <div class="row mt-3" v-if="!!cart.discount">
        <div class="col-8">
          <div class="checkout__label">Cart Discount</div>
        </div>
        <div class="col-4 text-right">
          <div class="checkout__label">
            -{{ cart.formated_discount }}
          </div>
        </div>
      </div>
      

      <hr />

      <div class="row mt-3">
        <div class="col-8">
          <div class="checkout__label">Subtotal</div>
        </div>
        <div class="col-4 text-right">
          <div class="checkout__label">
            {{ cart.formated_sub_total }}
          </div>
        </div>
      </div>

      

    
      
      <div class="checkout__label mt-3">Shipping</div>
      <p v-if="cart.shipping_method">
        {{ cart.selected_shipping_rate.method_description }}
        <span class="float-right">{{
          cart.selected_shipping_rate.formated_price
        }}</span>
      </p>
      <p v-else>Select the shipping</p>
      <p v-if="cart.rush_delivery_charge">
        Rush delivery charge
        <span class="float-right">{{
          cart.formated_rush_delivery_charge
        }}</span>
      </p>

      
      <div class="checkout__label mt-3" v-if="formated_base_taxes.length">Tax</div>
      <template v-for="(taxAmount, taxPercentage) in formated_base_taxes" :key="taxPercentage">
        <p v-if="taxPercentage != 0">
          {{ taxPercentage }}%
          <span class="float-right">{{ taxAmount }}</span>
        </p>
      </template>
      
      <div class="row">
        <div class="col-10">
          <div class="checkout__label donate">
              * Are you wish to donate {{donatableCents}}?
              <a href="" data-toggle="tooltip">
                <i class="fa fa-exclamation-circle"></i>  
                <span class="tooltip-content">give donate goes to NGO in Sri Lanka</span>
              </a>    
              <label for="no" style="margin:0 5px">
                <input type="radio" name="donate" id="no" value="no" v-model="donateCents"> No
              </label>
              <label for="yes"  style="margin-right:8px"><input type="radio" name="donate" id="yes" value="yes" v-model="donateCents"> Yes</label>
              <small v-if="isDonating">Please wait...</small>
          </div>
        </div>
        <div class="col-sm-2 text-right">
          <div class="checkout__label">{{ cart.formated_donate }}</div>
        </div>
      </div>

      <div class="section--shopping-cart coupon_style">
        <div class="shopping-cart__block" style="padding: 0">
          <div class="input-group" v-if="!cart.coupon_code">
            <input
              class="form-control"
              type="text"
              placeholder="Coupon code"
              v-model="coupon.code"
            />
            <div class="input-group-append">
              <button
                class="btn"
                @click="applyCouponCode"
                :disabled="!coupon.code.length"
              >
                Apply
              </button>
            </div>
          </div>
          <div v-else class="coupon-added">
            Coupon Code:
            <div class="float-right">
              {{ cart.coupon_code }}
              <a href="javascript:void(0)" @click.prevent="deleteCouponCode()"
                ><i class="fa fa-times"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div class="row">
        <div class="col-8">
          <div class="checkout__total">Total</div>
        </div>
        <div class="col-4 text-right">
          <div class="checkout__money">
            {{ cart.formated_grand_total }}
          </div>
        </div>
      </div>
    </div>
    <small>* Please make sure your donation. should be 'Yes' or No</small>


    <div class="cc-selector-2" v-if="false">
      <!-- <input checked="checked" id="visa2" type="radio" name="creditcard" value="visa" /> -->
      <label class="drinkcard-cc visa" for="visa2" style="margin-left:0"></label>
      <!-- <input id="mastercard2" type="radio" name="creditcard" value="mastercard"/> -->
      <label class="drinkcard-cc mastercard" for="mastercard2"></label>
      <!-- <input id="paypal2" type="radio" name="creditcard" value="paypal"/> -->
      <label class="drinkcard-cc paypal" for="paypal2"></label>
      <!-- <input id="americanex2" type="radio" name="creditcard" value="americalex"/> -->
      <label class="drinkcard-cc americanex" for="americanex2"></label>
    </div>    
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Checkout",

  data() {
    return {
      seen: true,
      hide: false,
      processOrder: false,
      donateCents:'',
      isDonating:false,
      coupon: { code: "" },
      formData: {
        agree: false,
        payment: {
          method: "cashondelivery",
        },
      },
    };
  },
  computed: {
    ...mapGetters("cart", ["cart", "isEmpty", "items", "unitPriceTotal", "formated_base_taxes",'productDiscount']),

    is_donate() {
      if (this.cart && this.cart.is_donate) {
        return this.cart.is_donate;
      }
      return false;
    },
    donatableCents () {
      return this.cart.formated_donatable
    }
  },

mounted() {
  this.assignState({key:'donate',value:this.donateCents})
},
  watch: {
    'donateCents':{
      handler(){
        this.isDonating = true
        this.saveDonate({
          'is_donate': this.donateCents == 'yes' ? true : false
        }).then(()=>{
          this.isDonating = false
          this.assignState({key:'donate',value:this.donateCents})
        })
      }
    }
  },

  methods: {
    ...mapActions("cart", [
      "savePayment",
      "saveOrder",
      "saveCoupon",
      "fetchCart",
      "removeCoupon",
      "saveDonate",
      'assignState'
    ]),
    handlePlaceOrder() {
      this.saveOrder().then((responce) => {
        if (responce.redirect_url) {
          window.location.href = responce.redirect_url;
          // this.$router.push({ name: "order-tracking" });
        }
      });
    },
    applyCouponCode() {
      this.saveCoupon(this.coupon).then((c) => {
        if (c) {
          this.fetchCart();
        }
      });
    },
    onIsDonate(isDonate) {
      this.saveDonate({
        'is_donate': isDonate
      });
    },
    deleteCouponCode() {
      this.removeCoupon().then((c) => {
        if (c) {
          this.fetchCart();
        }
      });
    },
  },
};
</script>
<style>
a.checkout__order.disabled {
  background: #ffd6b6;
  cursor: not-allowed;
}
.icon-cross {
  padding: 1px;
  background-color: #d92022;
  color: #ffff;
  border-radius: 50px;
  font-size: 10px;
}
#help {
  display: none;
}
#show:hover ~ #help {
  display: inline;
  background-color: rgba(0, 0, 0, 0.747);
  color: white;
  border-radius: 3px;
  padding: 2px;
  margin-left: 1px;
  margin-right: 1px;
  font-size: 11px;
}
.icon-help {
  font-weight: bold;
  background-color: #1877f2;
  color: white;
  padding: 2px 4px 2px 3px;
  border-radius: 10px;
  font-size: 10px;
  cursor: pointer;
}

.coupon_style{
  padding-bottom:0px;
}

a.donate-btn {
  padding: 1px 6px;
  margin-right: 3px;
  font-size: 10px;
  border-radius: 4px;
  margin-left:10px;
}

a.donate-btn.yes:hover, 
a.donate-btn.no:hover{  
   background: #949494;
}

a.donate-btn.yes{
  background: #28a745;
  color: #fff;
}

a.donate-btn.no{
  background: #d6d6d6;
  color: #000;
}

.coupon-added {
    background: #f7f7f7;
    font-weight: bold;
}
.donate label{
  font-weight: bold;
}

.donate label[for="yes"]{
  color: green;
}

.donate label[for="no"]{
  color: red;
}

[data-toggle="tooltip"] .tooltip-content{
  display: none;
  position: absolute;
  width: 320px;
  background: #000;
  color: #fff;
  padding: 3px 13px;
  margin-left: -7rem;
  margin-top: -3rem;
  border-radius: 17px;
  font-size: 13px;
  transition: all 5s;
}

[data-toggle="tooltip"]:hover .tooltip-content{
  display: inline;
}
</style>