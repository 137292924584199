<template>
  <div class="row">
    <div class="col-12 col-lg-8 offset-lg-2">
      <div class="checkout__header__box">
        <p>
          <i class="icon-user"></i>
          <template v-if="!isAuthenticated">
            Returning customer?
            <a href="" @click.prevent="$router.push({ name: 'login' })">Click here to login</a>
          </template>
          <template v-else> Welcome back, {{ user.name }} </template>
        </p>
        <!-- <i class="icon-chevron-down"></i> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Checkout",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "user"]),
  },
};
</script>

<style>
a.checkout__order.disabled {
  background: #ffd6b6;
  cursor: not-allowed;
}
.icon-cross {
  padding: 1px;
  background-color: #d92022;
  color: #ffff;
  border-radius: 50px;
  font-size: 10px;
}
</style>
